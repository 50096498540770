export default {
  noindex: process.env.NO_INDEX === 'true',
  nofollow: process.env.NO_FOLLOW === 'true',
  title: 'Sentinel – Search Self Storage Near You',
  description:
    'Sentinel has over 140 locations nationwide across Canada. Our locations are safe, convenient, secure and affordable. Call or visit us in store today!',
  openGraph: {
    title: 'Sentinel',
    description:
      'Sentinel has over 140 locations nationwide across Canada. Our locations are safe, convenient, secure and affordable. Call or visit us in store today!',
      url: 'https://www.sentinel.ca/en/',
      site_name: 'Sentinel',
    type: 'website',
    images: [
      {
        url: 'https://cdn-svi.sstg.ca/access_social_3add884314.jpg',
        width: 800,
        height: 600,
        alt: 'Og Image Alt 2',
      },
    ],
  },
};



