export const intercomLog = (message: string) => {
    if (!window.intercomLog || window.intercomLog.length >= 100) window.intercomLog = [];
    window.intercomLog.push(`${new Date().toLocaleTimeString()} - ${message}`);
}

export const handleIntercom = () => {
    intercomLog('openIntercom started');
    window.intercomFirstMessage = false;

    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    // The interval is used to await Intercom
    const interval = setInterval(() => {
        if (typeof window.Intercom === 'undefined') {
            intercomLog('Interval: Intercom not defined');
            return;
        }

        intercomLog('Interval: Intercom is defined');
        // When opening the chat for the first time in this session skip showing the "message list"
        window.Intercom('onShow', function() {
            if (!window.intercomFirstMessage) {
                window.intercomFirstMessage = true;
                window.Intercom('showNewMessage')
            }
        });
        // If the user is on desktop, pop the chat open on page load
        if (!isMobile && !isTablet) {
            // window.Intercom('show');
        }

        clearInterval(interval);
    }, 2000);
}
